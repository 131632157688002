export const customerSettings = {
COMPANY_CODE: "brussels",
MODE: "production",
ENV: "production",
VERSION: 1.1,
DOMAIN: 'stock.dieterenmobilitycompany.be',
SCRIPTS: [],
FONTS: [],
SENTRY_DSN: "",
LANGUAGES: ['nl', 'fr'],
FORCE_SINGLE_LANGUAGE_IN_URL: false,
WEBSITE_NAME: "",
IMAGE_PROMO_POSITION: "",
PATH_PREFIX: "",

GOOGLE_ANALYTICS_IDENTIFICATION: '',
GOOGLE_ANALYTICS_ID: '',
GOOGLE_TAG_MANAGER_ID: ['GTM-TKJQX59', 'GTM-PZR83RHK'],
GDDL_ID: 'GTM-TKJQX59',
HOTJAR_ID: '',
MAPBOX_TOKEN: 'pk.eyJ1IjoiYXV0cmFsaXMiLCJhIjoiY2toeXR2emhwMDk0OTJ6cWh0Y3JjeG5kaSJ9.YCvAWa5Wyqof0wPdueve5w',
RECAPTCHA_KEY: '6LcZyaUZAAAAAOrMBxDUfdVDq2xKcHORceibmov3',
INTERCOM_ID: '',
FINANCING: true,
FINANCING_SETTINGA: 'Example',
FINANCING_TEXT: true,
FAVORITES: true,
VEHICLE_COMPARE: true,
CONSENT: false,
CONSENT_SCRIPT_URL: '//nexus.ensighten.com/dieteren/lma_573_prod/Bootstrap.js',
SEO: true
};